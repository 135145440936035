import {Button, Checkbox, Col, Form, Input, message, Row, Switch} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {MapContainer, Polygon, Popup, TileLayer, Tooltip, LayersControl, Marker} from "react-leaflet";
import instance from "../../config/axios_config";
import ControlMapCustom from "./control";
import DrawPolygon from "./draw";

export const Map = ({center, zoom, mode = "view", item, clearData}) => {
    const markerRef = useRef(null)
    const [form] = Form.useForm();
    const map = useRef(null)
    const [layers, setLayers] = useState([])
    const [geom, setGeom] = useState([])
    const [isDraw, setIsDraw] = useState(false);

    useEffect(() => {
        if (markerRef.current) {
            markerRef?.current?.addEventListener("click", (e) => {
                console.log(e)
            });
        }
    }, [markerRef]);

    useEffect(() => {
        if (item?.geom?.length) {
            setLayers([<Polygon
                key={layers.length}
                name={item?.name}
                ref={markerRef}
                positions={item?.geom}
                pathOptions={{
                    fillColor: "red", color: "red"
                }}
            >
                <Tooltip direction="bottom" offset={[0, -10]} opacity={1} permanent>
                    {item?.name}
                </Tooltip>
            </Polygon>
            ]);
            if (item) {
                form.setFieldsValue(item)
            }
        }
    }, [item])

    useEffect(() => {
        if (geom?.length && isDraw) {
            setLayers([<Polygon
                key={layers.length}
                ref={markerRef}
                positions={geom}
                pathOptions={{
                    fillColor: "red", color: "red"
                }}
            >
            </Polygon>
            ])
        }
    }, [geom])

    const onFinish = async (values) => {
        if (mode === "update" || geom?.length) {
            try {
                let data = new FormData();
                let makeGeom = [];
                if (geom?.length) {
                    makeGeom = geom;
                    makeGeom.push(geom[0]);
                }
                data.append("name", values?.name)
                data.append("active", values?.active === true);
                data.append("geom", JSON.stringify(makeGeom?.length ? geom : item.geom));
                if (mode === "update") {
                    data.append("id", item.id)
                }
                let resp = await instance({
                    method: 'post',
                    url: "/create-or-update-location",
                    data: data
                })
                message.success(resp.data.message);
                clearData();
                setGeom([])
            } catch (e) {
                message.error(e.response.data.message)
            }
        } else message.error("Please make a geolocation!")
    }

    return (<div>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    {["create", "update"].includes(mode) ? <Col span={20}>
                        <Form.Item rules={[{
                            required: true, message: "Name is require!"
                        }]} label={"Name"} name="name">
                            <Input placheolder={"Input location name!"}/>
                        </Form.Item>
                    </Col> : ''}
                    {["create", "update"].includes(mode) ? <Col span={4}>
                        <Form.Item label="Active" name={"active"} valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                    </Col> : ''}
                    {["create", "update", "view"].includes(mode) ?
                        <Col className="pt-2" style={{border: "solid 1px rgba(0,0,0,0.5)", borderRadius: "7px"}}
                             span={24}>
                            <MapContainer
                                center={center}
                                zoom={zoom}
                                style={{width: '100%', height: "400px"}}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                />
                                {layers?.map(item => item)}
                                <ControlMapCustom center={center} zoom={zoom}/>
                                <DrawPolygon isDraw={isDraw} geom={geom} setGeom={setGeom} setLayers={setLayers}
                                             layers={layers}/>
                            </MapContainer>
                            {["create", "update"].includes(mode) ? <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "10px 10px",
                                    background: "white",
                                    alignItems: "center"
                                }}>
                                <Checkbox checked={isDraw} onChange={(e) => {
                                    setIsDraw(e.target.checked);
                                }
                                }> Draw</Checkbox>

                                <Button className="mx-1" onClick={() => {
                                    setLayers([])
                                    setGeom([])
                                }}>Clear</Button>
                            </div> : ''}
                        </Col> : ''}
                    {["create", "update"].includes(mode) ? <Col className="d-flex justify-content-end mt-3" span={24}>
                        <Button onClick={() => clearData()} className="mx-2" htmlType="button">Cancel</Button>
                        <Button htmlType="submit" type="primary">Save</Button>
                    </Col> : ''}
                </Row>
            </Form>
        </div>
    );
};
