import {FileExcelOutlined} from "@ant-design/icons";
import {Button, Col, DatePicker, message, Row} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, {useEffect, useRef, useState} from 'react'
import {DownloadTableExcel} from "react-export-table-to-excel";
import instance from "../../config/axios_config";

const EmployeeTable = () => {
    const [days, setDays] = useState({workDays: [], holidays: []});
    const [users, setUsers] = useState({count: 0, rows: []});
    const [tabelData, setTabelData] = useState({})
    const [today, setToday] = useState(new Date().getDate())
    const [loader, setLoader] = useState(false);
    const [param, setParam] = useState({})
    const [distance, setDistance] = useState([])
    const tableRef = useRef();
    useEffect(() => {
        let date = param?.date ? new Date(param?.date) : new Date();
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
        let arr = [];
        let holidays = []
        for (let i = 1; i <= lastDay; i++) {
            if ([0, 6].includes(new Date(date.getFullYear(), date.getMonth(), i).getDay())) {
                holidays.push(i)
            }
            arr.push(i);
        }
        setDistance([`01/${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}/${date.getFullYear()}`,
            `${lastDay}/${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}/${date.getFullYear()}`])
        setDays({workDays: arr, holidays: holidays})
    }, [param]);

    useEffect(() => {
        (
            async () => {
                try {
                    setLoader(true)
                    let data = await instance({
                        method: "get",
                        url: "/user/get-all-users"
                    })
                    setUsers(data.data.data)
                    setLoader(false)
                } catch (e) {
                    message.error(e.response.data.message)
                    setLoader(false)
                }
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                try {
                    setLoader(true)
                    let data = await instance({
                        method: "get",
                        url: "/user/attendance-table",
                        params: param
                    })
                    setTabelData(data.data.data)
                    setLoader(false)
                } catch (e) {
                    message.error(e.response.data.message)
                    setLoader(false)
                }
            }
        )()
    }, [param]);

    const isThisYearAndMonth = (id, workDay = true) => {
        let wDays = tabelData[id]?.filter(f => f?.total_hours !== 0)?.length
        let hDays = tabelData[id]?.filter(f => f?.total_hours === 0 && !days.holidays.includes(f.day))?.length
        if (!param?.date) {
            return workDay ? wDays === 0 ? wDays : wDays - 1 : hDays > 0 ? hDays - 1 : hDays;
        } else {
            return workDay ? wDays : hDays;
        }
    }

    return (
        <Row gutter={24}>
            <Col span={24} className="d-flex justify-content-end my-2">
                <DatePicker className={"mx-2"} picker="month" onChange={(e, str) => {
                    if (str) {
                        setParam({date: str})
                    } else {
                        setParam({})
                    }
                }}/>
                <DownloadTableExcel
                    filename="employee"
                    sheet="employees"
                    currentTableRef={tableRef.current}
                >
                    <Button icon={<FileExcelOutlined/>} className="bg-success text-light">Excel</Button>
                </DownloadTableExcel>
            </Col>
            <Col span={24}>
                <table ref={tableRef} className="table table-bordered text-center">
                    <thead>
                    <tr>
                        <td colSpan={days.workDays.length}>
                            <div><strong>INHA</strong></div>
                            <div className="d-flex align-items-start justify-content-center" style={{
                                fontSize: "10px",
                                top: "-10px",
                                borderTop: "1px solid rgba(0,0,0,0.4)"
                            }}>tashkilotning nomi
                            </div>
                        </td>
                        <td></td>
                        <td colSpan={5}><strong>Hisobot davri</strong></td>
                    </tr>
                    <tr>
                        <td colSpan={days.workDays.length}>
                            <div><strong>GEO2 IUT GLOBAL</strong></div>
                            <div className="d-flex align-items-start justify-content-center" style={{
                                fontSize: "10px",
                                top: "-10px",
                                borderTop: "1px solid rgba(0,0,0,0.4)"
                            }}>
                                tarkibiy bo'linma nomi
                            </div>
                        </td>
                        <td></td>
                        <td style={{width: "120px"}} colSpan={2}>
                            <div><strong>dan</strong></div>
                            <div className="d-flex align-items-start justify-content-center" style={{
                                fontSize: "14px",
                                top: "-10px",
                                borderTop: "1px solid rgba(0,0,0,0.4)"
                            }}>
                                {distance?.length > 0 ? distance[0] : ''}
                            </div>
                        </td>
                        <td colSpan={1}></td>
                        <td style={{width: "120px"}} colSpan={2}>
                            <div><strong>gacha</strong></div>
                            <div className="d-flex align-items-start justify-content-center" style={{
                                fontSize: "14px",
                                top: "-10px",
                                borderTop: "1px solid rgba(0,0,0,0.4)"
                            }}>
                                {distance?.length > 1 ? distance[1] : ''}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th colSpan={days.workDays.length + 6}>
                            Foydalanilgan ish vaqti hisobini yuritish <br/>TABELI
                        </th>
                    </tr>
                    <tr>
                        <th style={{verticalAlign: "middle"}} rowSpan={2}>T/r</th>
                        <th style={{verticalAlign: "middle"}} rowSpan={2}>Tabel raqami</th>
                        <th style={{verticalAlign: "middle"}} rowSpan={2}>F.I.SH</th>
                        <th style={{verticalAlign: "middle"}} rowSpan={2}>Lavozimi</th>
                        <th colSpan={days.workDays.length}>Oy kunlari va belgilar</th>
                        <th style={{verticalAlign: "middle", writingMode: "vertical-rl", lineHeight: "13px"}}
                            rowSpan={2}>Ishga <br/> qatnashgan <br/> kunlar
                        </th>
                        <th style={{verticalAlign: "middle", writingMode: "vertical-rl", lineHeight: "13px"}}
                            rowSpan={2}>Ishga <br/> qatnashmagan <br/> kunlar
                        </th>
                    </tr>
                    <tr>
                        {
                            days.workDays.map(item => <th
                                className={days.holidays.includes(item) ? 'bg-warning text-light' : !param?.date && today === item ? "bg-info text-light" : ''}
                                key={item}>{item}</th>)
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        users?.rows?.map((item, i) =>
                            <tr>
                                <td>{i + 1}</td>
                                <td></td>
                                <td>{item.lastname + " " + item.firstname}</td>
                                <td>Dasturchi</td>
                                {
                                    days.workDays.map(day => <td
                                        className={days.holidays.includes(day) ? 'bg-warning text-light' : !param?.date && today === day ? "bg-info text-light" : ''}
                                        key={day}>{days.holidays.includes(day) ? "V" : tabelData[`${item.id}`]?.find(dt => dt.day === day)?.total_hours ?? 0}</td>)
                                }
                                <td>{isThisYearAndMonth(item.id)}</td>
                                <td>{isThisYearAndMonth(item.id, false)}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}
export default EmployeeTable;