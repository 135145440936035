import React from "react";
import {useMapEvent} from "react-leaflet";

const DrawPolygon = ({setLayers, layers, isDraw, geom, setGeom}) => {
    useMapEvent("click", (e) => {
        if (isDraw) {
            setGeom([...geom, [e.latlng.lat, e.latlng.lng]]);
        }
    });

    return <></>
}

export default DrawPolygon