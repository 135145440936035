import axios from "axios";
import {useEffect, useState} from "react";
import Structure from "./components/structure";
import "./App.css";
import {useDispatch, useSelector} from "react-redux";
import LoginPage from "./components/login";
import {ACCESS_TOKEN, SOCKET_URL} from "./config/constants";
import {message, Spin} from "antd";
import {setAuth} from "./redux/auth/auth_slice";
import instance from "./config/axios_config";

function App() {
    const auth = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    let dispatch = useDispatch();
    useEffect(() => {
        (async () => {
            let token = localStorage.getItem(ACCESS_TOKEN);
            if (token) {
                try {
                    let me = await instance({
                        method: "get",
                        url: "/me",
                    });
                    if (me.data.status === 1) {
                        dispatch(setAuth(true));
                        setLoading(false);
                    } else {
                        dispatch(setAuth(false));
                        localStorage.removeItem(ACCESS_TOKEN)
                        message.error(me.data.message);
                    }
                } catch (error) {
                    console.log(error)
                    message.error(error.response.data.message);
                    dispatch(setAuth(false));
                    localStorage.removeItem(ACCESS_TOKEN)
                }
            } else {
                setLoading(false)
            }
        })();
    }, []);
    return loading ? (
        <div className="loading">
            <Spin size="large"/>
        </div>
    ) : auth?.isAuth ? (
        <Structure/>
    ) : (
        <LoginPage/>
    );
}

export default App;
