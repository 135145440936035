const CustomBadge = ({text, status = 'default', title = '', style = {}}) => {
    const styles = {
        success: {
            backgroundColor: '#52c41a',
            color: '#fafafa',
            borderRadius: '10px',
            padding: '2px 5px'
        },
        warning: {
            backgroundColor: '#faad14',
            color: '#fafafa',
            borderRadius: '10px',
            padding: '2px 5px'
        },
        error: {
            backgroundColor: '#f5222d',
            color: '#fafafa',
            borderRadius: '10px',
            padding: '2px 5px'
        },
        default: {
            backgroundColor: '#8c8c8c',
            color: '#fafafa',
            borderRadius: '10px',
            padding: '2px 5px'
        },
        info: {
            backgroundColor: '#1677ff',
            color: '#fafafa',
            borderRadius: '10px',
            padding: '2px 5px'
        },
    };
    return <span title={title} className="custom-badge" style={{...styles[status], ...style}}>{text}</span>
}

export default CustomBadge;