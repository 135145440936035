import {useEffect} from "react";
import {useMap} from "react-leaflet";

const ControlMapCustom = ({center, zoom}) => {
    let map = useMap();
    useEffect(() => {
        map.flyTo(center, zoom)
    }, [zoom, center])
    return <></>
}

export default ControlMapCustom