import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {
    Avatar,
    Badge,
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    List,
    message,
    Modal,
    Popconfirm,
    Row,
    TimePicker
} from "antd";
import moment from "moment";
import {useEffect, useState} from "react";
import instance from "../../config/axios_config";
import {BASE_URL} from "../../config/constants";

const SendNotification = () => {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [users, setUsers] = useState({count: 0, rows: []});
    const [schedules, setSchedules] = useState([]);
    const [refreshSch, setRefreshSch] = useState(false)
    const [loader, setLoader] = useState(false);
    const [modalOpt, setModalOpt] = useState({
        open: false,
        item: undefined,
        type: ""
    })
    const [ids, setIds] = useState([])

    useEffect(() => {
        (
            async () => {
                try {
                    setLoader(true)
                    let data = await instance({
                        method: "get",
                        url: "/user/get-all-users"
                    })
                    let schedules = await instance({
                        method: "get",
                        url: "/notification/get-all-schedule"
                    });
                    setSchedules(schedules?.data?.data)
                    setUsers(data.data.data)
                    setLoader(false)
                } catch (e) {
                    message.error(e.response.data.message)
                    setLoader(false)
                }
            }
        )()
    }, [refreshSch])

    const onFinish = async (vals) => {
        if (ids.length > 0) {
            try {
                let data = new FormData();
                data.append("title", vals?.title)
                data.append("body", vals?.body);
                data.append("ids", ids.join(","))
                let resp = await instance({
                    method: "POST",
                    url: "/notification/send",
                    data: data
                })
                message.success(resp.data.message)
                form.resetFields();
                setIds([])
            } catch (e) {
                message.error(e.response.data.message)
            }
        } else {
            message.info("Iltimos foydalanuvchini belgilang!")
        }
    }

    const finishSchedule = async (vals) => {
        try {
            let data = new FormData();
            if (modalOpt.item) {
                data.append("id", modalOpt.item.id)
            }
            data.append("title", vals.title)
            data.append("message", vals.message);
            data.append("time", new Date(vals.time["$d"]).toLocaleTimeString('en-EN', {
                timeZone: 'Asia/Tashkent',
                hourCycle: 'h23',
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            }).replaceAll("/", "-").replaceAll(",", ""));
            let resp = await instance({
                method: "post",
                url: '/notification/create-or-update-schedule',
                data: data
            })
            message.success(resp.data.message);
            setModalOpt({
                open: false,
                type: "",
                item: undefined
            });
            form1.resetFields();
            setRefreshSch(!refreshSch)
        } catch (e) {
            message.error("Error")
        }
    };

    const deleteSchedule = async (id) => {
        try {
            let resp = await instance({
                method: "delete",
                url: `/notification//delete-schedule/${id}`
            })
            message.success(resp.data.message);
            setRefreshSch(!refreshSch)
        } catch (e) {
            message.error("Error delete schedule")
        }
    }
    return (<div>
        <h3>Bildirishnomalar</h3>
        <Row gutter={24}>
            <Col span={10} style={{border: "1px solid rgba(0,0,0,.4)"}}>
                <div className="p-3">
                    <Checkbox checked={users.rows.every(item => ids.includes(item.id))} onChange={(e) => {
                        if (e.target.checked) {
                            setIds(users.rows.map(itm => itm.id))
                        } else {
                            setIds([])
                        }
                    }}>Barchasi</Checkbox>
                </div>
                <List
                    itemLayout="horizontal"
                    dataSource={users.rows}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={BASE_URL + '/file/' + item.images[0]}/>}
                                description={item.lastname + " " + item.firstname}
                            />
                            <Checkbox checked={ids.includes(item.id)} onChange={(e) => {
                                if (e.target.checked) {
                                    setIds([...ids, item.id])
                                } else {
                                    setIds(ids.filter(id => id !== item.id))
                                }
                            }}/>
                        </List.Item>
                    )}
                />
            </Col>
            <Col span={14}>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item rules={[{
                                required: true,
                                message: "Title is requre!"
                            }]} name="title" label="Sarlavha">
                                <Input allowClear/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item rules={[{
                                required: true,
                                message: "Body is requre!"
                            }]} name="body" label="Xabar matni">
                                <Input.TextArea allowClear style={{height: "210px"}}/>
                            </Form.Item>
                        </Col>
                        <Col span={24} className={'d-flex justify-content-end'}>
                            <Button type="primary" htmlType="submit">Yuborish</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
        <hr/>
        <Row gutter={24}>
            <Col span={24} className="d-flex justify-content-between align-items-center border-bottom">
                <h3>Doimiy bildirishnomalar</h3>
                <Button type="primary" onClick={() => {
                    setModalOpt({open: true, item: undefined, type: "create"})
                }}>+ Qo'shish</Button>
            </Col>
            <Col span={24}>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Sarlavha</th>
                        <th>Xabar matni</th>
                        <th>Vaqt</th>
                        <th>Holat</th>
                        <th width="100px">Harakatlar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {schedules?.map((item, i) =>
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item?.title?.length > 30 ? item?.title.substring(0, 30) + "..." : item.title}</td>
                            <td>{item?.message?.length > 60 ? item?.message.substring(0, 60) + "..." : item.message}</td>
                            <td>{new Date(item?.time)?.toLocaleTimeString('en-EN', {
                                timeZone: 'Asia/Tashkent',
                                hourCycle: 'h23',
                                hour: "2-digit",
                                minute: "2-digit"
                            })}</td>
                            <td>{item?.status ? <Badge status="success" text="Yuborilgan"/> :
                                <Badge status="processing" text="Kutilmoqda"/>}</td>
                            <td>
                                <div className="d-flex justify-content-evenly align-items-center">
                                    <EditOutlined onClick={() => {
                                        setModalOpt({
                                            item: item,
                                            type: "edit",
                                            open: true
                                        });
                                        form1.setFieldsValue({
                                            ...item,
                                            time: moment(item.time)
                                        })
                                    }} style={{color: "blue", cursor: "pointer"}}/>
                                    <Popconfirm title={"O'chirilsinmi?"} okText="Ha" cancelText="Yo'q"
                                                onConfirm={() => deleteSchedule(item.id)}>
                                        <DeleteOutlined style={{color: "red", cursor: "pointer"}}/>
                                    </Popconfirm>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </Col>
            <Modal footer={false} open={modalOpt.open} onCancel={() => {
                setModalOpt({open: false, item: undefined, type: ""});
                form1.resetFields()
            }}>
                <Form form={form1} layout="vertical" onFinish={finishSchedule}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="title" label="Sarlavha matni!">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="message" label="Xabar matni!">
                                <Input.TextArea showCount={true} maxLength={1000} rows={4}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="time" label="Sarlavha matni!">
                                <TimePicker format={"HH:mm"} showNow={false}/>
                            </Form.Item>
                        </Col>
                        <Col className="d-flex justify-content-end" span={24}>
                            <Button className="mx-2" htmlType="button" onClick={() => {
                                setModalOpt({
                                    open: false,
                                    type: "",
                                    item: undefined
                                });
                                form1.resetFields()
                            }} danger type="primary">
                                Bekor qilish
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Saqlash
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    </div>)
}

export default SendNotification