import {configureStore} from '@reduxjs/toolkit'
import authSlice from './auth/auth_slice'
import socketSlice from "./socket/socket_slice";

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        sockState: socketSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
})

export default store;