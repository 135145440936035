import moment from "moment";
import {useEffect, useState} from "react";
import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined, FileTextOutlined, MinusOutlined,
    PlusOutlined,
    UploadOutlined, UserAddOutlined, UserDeleteOutlined,
} from "@ant-design/icons";
import {
    Badge,
    Button, Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal, Pagination,
    Popconfirm, Row,
    Select,
    Spin,
    Tooltip,
    Upload,
} from "antd";
import "./style.css";
import instance from "../../config/axios_config";
import {BASE_URL} from "../../config/constants";

const usersData = [
    {
        id: 1,
        fName: "Jonibek",
        lName: "Ashirov",
        photos: "../../assets/images/Jonibek.jpg",
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 2,
        fName: "Jonibek",
        lName: "Ashirov",
        photos: "../../assets/images/Jonibek.jpg",
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 3,
        fName: "Dostonbek",
        lName: "G'oyipov",
        photos: "../../assets/images/Dostonbek.jpg",
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 4,
        fName: "Yunus",
        lName: "Boliyev",
        photos: "../../assets/images/Yunus.jpg",
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 5,
        fName: "Jonibek",
        lName: "Ashirov",
        photos: "../../assets/images/Jonibek.jpg",
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
];

const Attendences = () => {
    const [form] = Form.useForm();
    const [isModalOpenPhoto, setIsModalOpenPhoto] = useState(false);
    const [reasonModal, setReasonModal] = useState({open: false, item: undefined})
    const [loadingGet, setLoadingGet] = useState(false);
    const [loadingPost, setLoadingPost] = useState(false);
    const [data, setData] = useState({data: [], meta: {page: 0, size: 0, count: 0, pageSize: 0}});
    const [users, setUsers] = useState();
    const [refresh, setRefresh] = useState(false)
    const [params, setParams] = useState({
        page: 1,
        size: 10,
        date: [getDate(new Date(), "date"), getDate(new Date(), "date")]
    })

    useEffect(() => {
        (
            async () => {
                try {
                    let data = await instance({
                        method: "get",
                        url: "/user/get-all-users"
                    })
                    setUsers(data?.data?.data?.rows)
                } catch (e) {
                    message.error(e.response.data.message)
                }
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                try {
                    setLoadingGet(true)
                    let resp = await instance({
                        method: "get",
                        url: "/user/get-all-attendance",
                        params: getParams()
                    })
                    setData(resp.data);
                    setLoadingGet(false);
                    message.success("All attendances!")
                } catch (e) {
                    setLoadingGet(false);
                    message.error(e.response.data.message);
                }
            }
        )()
    }, [refresh, params])
    const getParams = () => {
        let param = {};
        Object.entries(params).map(([key, val]) => {
            console.log(key, val)
            if (val) {
                if (key === "date") {
                    param = {...param, [key]: JSON.stringify(val)}
                } else {
                    param = {...param, [key]: val}
                }
            }
        })
        return param
    }
    const handleOkPhoto = () => {
        setIsModalOpenPhoto(false);
    };
    const handleCancelPhoto = () => {
        setIsModalOpenPhoto(false);
    };

//   select value
    const handleChange = (value) => {
        setParams({...params, user_id: value})
    };

    function getDate(date, type = "date") {
        if (date) {
            let d = new Date(date)
            if (type === "date") {
                let day = d.getDate(), m = d.getMonth() + 1, y = d.getFullYear();
                return `${y}-${m < 10 ? '0' + m : m}-${day < 10 ? '0' + day : day}`
            } else {
                let now = new Date(date);
                now.setHours(type === "in" ? 9 : 18);
                now.setMinutes(0);
                now.setSeconds(0);
                now.setMilliseconds(0)
                let dist = now.getTime() - d.getTime();
                let sec = (dist / 1000).toFixed(0);
                sec = Math.abs(sec);
                let ddd = sec - sec % 3600
                let hour = ddd >= 3600 ? ddd / 3600 : 0, min = ((sec % 3600) / 60).toFixed(0);
                if (type !== "in") {
                    dist = -dist;
                }
                return <span>
                    <span>{d.toTimeString().substring(0, d.toTimeString().indexOf(" "))}</span>
                    <span className="mx-1"
                          style={{color: dist > 0 ? '#52c41a' : '#faad14'}}>({`${dist > 0 ? '+' : '-'}${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}`})</span>
                </span>
            }
        } else return <MinusOutlined style={{color: "#f5222d"}}/>
    }

    const workHours = (start, end) => {
        if (start) {
            let sd = new Date(start);
            let d = new Date();
            if (end) {
                d = new Date(end);
            }
            let dist = d.getTime() - sd.getTime();
            let sec = (dist / 1000).toFixed(0);
            let ddd = sec - sec % 3600
            let hour = ddd >= 3600 ? ddd / 3600 : 0, min = ((sec % 3600) / 60).toFixed(0);
            if (hour <= 14) {
                if (end && hour > 1) {
                    hour = hour - 1;
                }
                return `${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}`
            } else return ""
        } else {
            return "";
        }
    }

    console.log(new Date().toLocaleDateString().split("/").reverse().join("-"))
    const onFinish = async (values) => {
        try {
            setLoadingPost(true)
            let data = new FormData();
            data.append("message", values.message)
            let resp = await instance({
                method: "post",
                url: `/user/set-reason/${reasonModal.item.id}`,
                data: data
            })
            message.success(resp.data.message).then(() => {
                setRefresh(!refresh);
                setReasonModal({open: false, item: undefined});
                form.resetFields();
                setLoadingPost(false)
            })
        } catch (e) {
            message.error(e.response.data.message)
            setLoadingPost(false)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col d-flex justify-content-between align-content-center mb-3">
                    <h4>Davomad ( <span
                        className="text-info">{params?.date ? params?.date[0] !== params?.date[1] ? `${params?.date[0]} <-> ${params?.date[1]}` : params?.date[0] : ''}</span> )
                    </h4>
                    <div>
                        <Select
                            placeholder="Select user!"
                            allowClear
                            className="mx-2 primary"
                            style={{
                                width: 300,
                            }}
                            onChange={handleChange}
                            options={users?.map(item => {
                                return {
                                    value: item.id,
                                    label: `${item?.lastname} ${item?.firstname}`,
                                }
                            })}
                        />
                        <DatePicker.RangePicker style={{width: 300}} allowClear onChange={(e, str) => {
                            console.log(str)
                            setParams({
                                ...params,
                                date: e ? str : [getDate(new Date(), "date"), getDate(new Date(), "date")]
                            })
                        }}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {loadingGet ? <div className="loader-line"></div> : ''}
                    <table className="table table-striped table-hover hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            {/*<th scope="col">id</th>*/}
                            <th scope="col">Ismi</th>
                            <th scope="col">Familyasi</th>
                            <th scope="col">Kirish</th>
                            <th scope="col">Chiqish</th>
                            <th scope="col">Ishlagan soati</th>
                            <th scope="col">Ishdami</th>
                            <th scope="col">Sana</th>
                            <th scope="col">Qurulma</th>
                            <th scope="col">Sabab</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            // loading ? <div className="w-100 d-flex align-items-center justify-content-center"><Spin /></div>
                            data?.data?.map((item, i) => (
                                <tr key={i}>
                                    <td className="align-middle">{(params.page - 1) * params.size + (i + 1)}</td>
                                    {/*<td className="align-middle">*/}
                                    {/*    {item?.id}*/}
                                    {/*</td>*/}
                                    <td className="align-middle">
                                        {item?.firstname}
                                    </td>
                                    <td className="align-middle">
                                        {item?.lastname}
                                    </td>
                                    {/*<td className="align-middle">*/}
                                    {/*    <Tooltip title="Photo view">*/}
                                    {/*        <EyeOutlined*/}
                                    {/*            onClick={() => {*/}
                                    {/*                if (item?.image_path) {*/}
                                    {/*                    setIsModalOpenPhoto(true);*/}
                                    {/*                    setSelectUserPhotos(item?.image_path);*/}
                                    {/*                } else message.warning("Image not fount!")*/}
                                    {/*            }}*/}
                                    {/*            style={{*/}
                                    {/*                marginRight: "10px",*/}
                                    {/*                fontSize: "20px",*/}
                                    {/*                cursor: "pointer",*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </Tooltip>*/}
                                    {/*</td>*/}
                                    <td>{getDate(item?.check_in, "in") ??
                                        <MinusOutlined style={{color: "#f5222d"}}/>}</td>
                                    <td>{getDate(item?.check_out, "out") ??
                                        <MinusOutlined style={{color: "#f5222d"}}/>}</td>
                                    <td>{workHours(item.check_in, item.check_out)}</td>
                                    <td>{
                                        item.last_action === "check_in" ? <Badge text={"Ishda"} color={"green"}/> :
                                            <Badge text={"Ishda emas"} color={"orange"}/>
                                    }</td>
                                    <td>{item?.date}</td>
                                    <td>{item?.device_model}</td>
                                    <td>
                                        <Button onClick={() => {
                                            form.setFieldsValue({message: item?.message})
                                            setReasonModal({open: true, item: item});
                                        }}
                                                className="bg-warning"
                                                icon={!item.is_reason ? <CheckOutlined/> : <FileTextOutlined/>}/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                        <Pagination total={data.meta.count} size={data.meta.size} onChange={(e) => {
                            setParams({...params, page: e})
                        }}/>
                    </div>
                </div>
            </div>
            {/* View user photo modal */}
            <Modal title="Reason text!" open={reasonModal.open} onCancel={() => {
                setReasonModal({open: false, item: undefined})
                form.resetFields();
            }} footer={false}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name={"message"}>
                                <Input.TextArea style={{height: "200px"}} showCount maxLength={200}
                                                placeholder="Reason message!"/>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="d-flex justify-content-end">
                            <Button disabled={loadingPost} loading={loadingPost} htmlType="submit"
                                    type="primary">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
        ;
};

export default Attendences;
