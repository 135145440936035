import React, {useState} from "react";
import {
    CheckSquareOutlined, KeyOutlined, LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined, NotificationOutlined,
    SettingFilled,
    TableOutlined,
    UserOutlined,

} from "@ant-design/icons";
import {Button, Layout, Menu, theme} from "antd";
import {useDispatch} from "react-redux";
import {Route, Routes, useLocation, useNavigate} from "react-router";
import {ACCESS_TOKEN} from "../../config/constants";
import {setAuth} from "../../redux/auth/auth_slice";
import SendNotification from "../notification";
import UpdatePassword from "../settings/update_password";
import EmployeeTable from "../table";
import Users from "../users";
import Attendences from "../attendences";
import Settings from "../settings";

const {Header, Sider, Content} = Layout;

const Structure = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const keys = {
        "/": "1",
        "/employee_table": "2",
        "/attendences": "3",
        "/settings": "4",
        "/update_password": "5",
        "/send-notification": "6"
    }
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    const navigate = useNavigate();
    return (<Layout>
        <Sider
            style={{height: "100vh"}}
            trigger={null}
            collapsible
            collapsed={collapsed}
        >
            <div className="logo d-flex justify-content-center my-2 text-light align-items-center">
                <img className="mx-2" style={{width: 30}}
                     src="https://icon-library.com/images/attendance-icon/attendance-icon-7.jpg" alt=""/>
                {!collapsed ? <span style={{fontSize: "18px", fontWeight: "600"}}>ATTENDANCE</span> : ''}
            </div>
            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[`${keys[location.pathname]}`]}
                items={[
                    {
                        key: "1", icon: <UserOutlined/>, label: "Xodimlar", onClick: () => {
                            navigate("/");
                        },
                    }, {
                        key: "2", icon: <TableOutlined/>, label: "Tabel", onClick: () => {
                            navigate("/employee_table");
                        },
                    }, {
                        key: "3", icon: <CheckSquareOutlined/>, label: "Davomad", onClick: () => {
                            navigate("/attendences");
                        },
                    }, {
                        key: "4", icon: <SettingFilled/>, label: "Sozlashlar", onClick: () => {
                            navigate("/settings");
                        },
                    }, {
                        key: "5", icon: <KeyOutlined/>, label: "Parolni yangilash", onClick: () => {
                            navigate("/update_password");
                        },
                    }, {
                        key: "6", icon: <NotificationOutlined />, label: "Bildirishnomalar", onClick: () => {
                            navigate("/send-notification");
                        },
                    }
                ]}
            />
        </Sider>
        <Layout className="site-layout">
            <Header
                className="d-flex justify-content-between align-items-center"
                style={{
                    padding: 0, background: colorBgContainer,
                }}
            >
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: "trigger ms-3", style: {fontSize: 25}, onClick: () => setCollapsed(!collapsed),
                })}
                <div className="px-3">
                    <Button onClick={() => {
                        localStorage.removeItem(ACCESS_TOKEN);
                        dispatch(setAuth(false))
                    }} className="d-flex align-items-center"><LogoutOutlined/> Chiqish</Button>
                </div>
            </Header>
            <Content
                style={{
                    margin: "24px 16px", padding: 24, minHeight: 280, background: colorBgContainer,
                }}
            >
                <Routes>
                    <Route path={"/"} element={<Users/>}/>
                    <Route path={"/employee_table"} element={<EmployeeTable/>}/>
                    <Route path={"/attendences"} element={<Attendences/>}/>
                    <Route path={"/settings"} element={<Settings/>}/>
                    <Route path={"/update_password"} element={<UpdatePassword/>}/>
                    <Route path={"/send-notification"} element={<SendNotification/>}/>
                    {/*<Route path={"/web_camera"} element={<WebCameraComp/>}/>*/}
                </Routes>
            </Content>
        </Layout>
    </Layout>);
};
export default Structure;
