import axios from "axios";
import {ACCESS_TOKEN, BASE_URL} from "./constants";

const instance=axios.create();
instance.defaults.baseURL=BASE_URL;
const onRequestSuccess=(config)=>{
    if(localStorage.getItem(ACCESS_TOKEN)){
        config.headers.authorization=`Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    }
    return Promise.resolve(config);
}

const onRequestError=(config)=>{
    return Promise.reject(config)
}

const onResponseSuccess=(config)=>{
    return Promise.resolve(config);
}

const onResponseError=(config)=>{
    return Promise.reject(config)
}

instance.interceptors.request.use(onRequestSuccess,onRequestError);
instance.interceptors.response.use(onResponseSuccess,onResponseError);

export default instance;