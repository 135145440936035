import {useEffect, useState} from "react";
import {
    CopyrightCircleFilled, CopyTwoTone,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PlusOutlined, ReloadOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Badge,
    Button,
    Form, Image,
    Input,
    message,
    Modal,
    Popconfirm,
    Spin, Switch,
    Tooltip,
    Upload,
} from "antd";
import "./style.css";
import instance from "../../config/axios_config";
import {BASE_URL} from "../../config/constants";
import CustomBadge from "../extra/badge";

const usersData = [
    {
        id: 1,
        fName: "Jonibek",
        lName: "Ashirov",
        photos: [
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
        ],
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 2,
        fName: "Jonibek",
        lName: "Ashirov",
        photos: [
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
        ],
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 3,
        fName: "Dostonbek",
        lName: "G'oyipov",
        photos: [
            "../../assets/images/Dostonbek.jpg",
            "../../assets/images/Dostonbek.jpg",
            "../../assets/images/Dostonbek.jpg",
            "../../assets/images/Dostonbek.jpg",
        ],
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 4,
        fName: "Yunus",
        lName: "Boliyev",
        photos: [
            "../../assets/images/Yunus.jpg",
            "../../assets/images/Yunus.jpg",
            "../../assets/images/Yunus.jpg",
            "../../assets/images/Yunus.jpg",
        ],
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
    {
        id: 5,
        fName: "Jonibek",
        lName: "Ashirov",
        photos: [
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
            "../../assets/images/Jonibek.jpg",
        ],
        authKey: "csdcsdcsdcsdcsd",
        connectDevice: "Iphone 11",
    },
];

const Users = () => {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpenPhoto, setIsModalOpenPhoto] = useState(false);
    const [selectUserPhotos, setSelectUserPhotos] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState({open: false, item: undefined});
    const [files, setFiles] = useState([]);
    const [users, setUsers] = useState({count: 0, rows: []});
    const [loaderAction, setLoaderAction] = useState(false)
    const [loader, setLoader] = useState(false);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        (
            async () => {
                try {
                    setLoader(true)
                    let data = await instance({
                        method: "get",
                        url: "/user/get-all-users"
                    })
                    setUsers(data.data.data)
                    setLoader(false)
                } catch (e) {
                    message.error(e.response.data.message)
                    setLoader(false)
                }
            }
        )()
    }, [refresh])


    const normFileAdd = (e) => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const handleOkPhoto = () => {
        setIsModalOpenPhoto(false);
    };
    const handleCancelPhoto = () => {
        setIsModalOpenPhoto(false);
    };

    const confirm = (e) => {
        console.log(e);
        message.success("Click on Yes");
    };
    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    const onFinish = async (values) => {
        if (files.length > 1) {
            let url = isModalOpen.item ? `/user/update/${isModalOpen.item.id}` : "/user/post-user";
            try {
                setLoaderAction(true)
                let data = new FormData();
                let old_photos = []
                Object.entries(values).map(([key, val]) => {
                    if (key === "images") {
                        val.fileList?.map(item => {
                            if (item?.url && item?.url.startsWith(BASE_URL)) {
                                let url = item.url.split("/");
                                old_photos.push(url[url.length - 1]);
                            } else {
                                data.append("files", item.originFileObj)
                            }
                        })
                    } else {
                        data.append(key, val);
                    }
                })
                if (isModalOpen.item && old_photos.length) {
                    data.append("old_files", old_photos.join(","))
                }
                let resp = await instance({
                    url: url,
                    method: "post",
                    data: data
                })
                setRefresh(!refresh)
                message.success(resp.data.message)
                setLoaderAction(false)
                form.resetFields()
                setFiles([])
                setIsModalOpen({open: false, item: undefined})
            } catch (e) {
                setLoaderAction(false)
                message.error(e.response.data.message)
            }
        } else {
            form.setFields([{
                name: "images",
                errors: ["The number of photos must be more than 2!"],
                validating: false
            }])
        }
    }


    const reGenerateKey = async (id) => {
        try {
            let res = await instance({
                method: "post",
                url: `/user/refresh-short-code/${id}`
            })
            setRefresh(!refresh);
            message.success(res.data.message)
        } catch (e) {
            message.error(e.response.data.message)
        }
    }

    const deleteUser = async (id) => {
        try {
            let res = await instance({
                method: "delete",
                url: `/user/delete/${id}`
            })
            setRefresh(!refresh);
            message.success(res.data.message)
        } catch (e) {
            message.error(e.response.data.message)
        }
    }

    const blockUser = async (id) => {
        try {
            let res = await instance({
                method: "post",
                url: `/user/block/${id}`
            })
            setRefresh(!refresh);
            message.success(res.data.message)
        } catch (e) {
            message.error(e.response.data.message)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col d-flex justify-content-between align-content-center mb-3">
                    <h4>Users</h4>
                    <Button onClick={() => setIsModalOpen({open: true, item: undefined})} type="primary">
                        + Add
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {loader ? <div className="loader-line"></div> : ''}
                    <table className="table table-striped table-hover hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ID</th>
                            <th scope="col">Avatar</th>
                            <th scope="col">Ism</th>
                            <th scope="col">Familya</th>
                            <th scope="col">Bir martalik kod</th>
                            <th scope="col">Qurulmaga ulangan</th>
                            <th scope="col">Qurulma</th>
                            <th scope="col">Bloklash</th>
                            <th style={{width: '100px'}} scope="col">Harakatlar</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            // loading ? <div className="w-100 d-flex align-items-center justify-content-center"><Spin /></div>
                            users.rows.map((item, i) => (
                                <tr key={i}>
                                    <th style={{width:15}} scope="row">{i + 1}</th>
                                    <th style={{width:15}} scope="row">{item.id}</th>
                                    <th style={{width:35}} scope="row"><Avatar size={35}
                                        src={<Image src={`${BASE_URL}/file/${item?.images[0]}`}/>}/></th>
                                    <td>{item?.firstname}</td>
                                    <td>{item?.lastname}</td>
                                    <td
                                        className="authKey"
                                    >
                                        {item?.short_code ?
                                            <CustomBadge
                                                style={{textDecoration: item?.short_code_status ? '' : 'line-through'}}
                                                title={item?.short_code_status ? '' : 'Kod holati foydalanishga yaroqsiz!'}
                                                status={item?.short_code_status ? 'success' : 'warning'}
                                                text={item?.short_code}/> : ''}
                                        {item?.short_code ? <Tooltip title={<span>
                                        {item?.short_code_status ? '' :
                                            <span className="text-warning">Kod foydalanishga yaroqsiz! </span>}
                                            <span>Nusxalash uchun cherting!</span>
                                            </span>}> <CopyTwoTone
                                            style={{cursor: "pointer"}} onClick={() => {
                                            navigator.clipboard.writeText(item?.short_code);
                                            message.success(item?.firstname + ' ' + item?.lastname + 'ning bir martalik kodi nusxalandi');
                                        }}/></Tooltip> : ''}
                                        <Popconfirm
                                            okText={"Ha"}
                                            okButtonProps={{type: 'primary', danger: true}}
                                            cancelText={"Yo'q"}
                                            onConfirm={() => reGenerateKey(item.id)}
                                            title={"Qisqa kodni qayta yaratish foydalanuvchi qurulmsi o'chib ketishiga olib keladi! Qayta yaraatish tasdiqlansinmi?"}>
                                            <Tooltip title="Bir martalik kodni qayta yaratish!"> <ReloadOutlined
                                                style={{cursor: "pointer", color: "red"}}
                                            /></Tooltip>
                                        </Popconfirm>
                                    </td>
                                    <td>{item.connect_device ?
                                        <CustomBadge status={"success"} text={'ulangan'.toUpperCase()}/> :
                                        <CustomBadge status={"warning"} text={'ulanmagan'.toUpperCase()}/>}</td>
                                    <td>{item?.device_model}</td>
                                    <td><Switch checked={item.is_blocked} onChange={(e) => blockUser(item.id)}/></td>
                                    <td className="action">
                                        <Tooltip title="Edit">
                                            <EditOutlined
                                                onClick={() => {
                                                    setIsModalOpen({open: true, item: item});
                                                    form.setFieldsValue(item);
                                                    setFiles(item?.images?.map((img, i) => {
                                                        return {
                                                            uid: `${i}`,
                                                            name: "Photo",
                                                            url: BASE_URL + '/file/' + img,
                                                            status: "done"
                                                        }
                                                    }))
                                                }}
                                                style={{
                                                    color: "#52c41a",
                                                    marginRight: "10px",
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                }}
                                                className="edit icon"
                                                color="green"
                                            />
                                        </Tooltip>
                                        {/* <Tooltip title="Delete"> */}
                                        <Popconfirm
                                            title="Delete the user"
                                            description="Are you sure to delete this user?"
                                            onConfirm={() => deleteUser(item.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined
                                                style={{
                                                    color: "#eb2f96",
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                }}
                                                className="delete icon"
                                                color="red"
                                            />
                                        </Popconfirm>
                                        {/* </Tooltip> */}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                title="User Photos"
                open={isModalOpenPhoto}
                onOk={handleOkPhoto}
                footer={false}
                onCancel={handleCancelPhoto}
            >
                <div className="row" style={{maxHeight: "60vh", overflowY: "scroll"}}>
                    {selectUserPhotos.map((item, inx) => (
                        <div key={inx} className="col-6 mb-4">
                            <img width={"100%"} src={BASE_URL + "/file/" + item} alt="img"/>
                        </div>
                    ))}
                </div>
            </Modal>
            <Modal
                title={isModalOpen.item ? "Edit user" : "Create user!"}
                open={isModalOpen.open}
                onCancel={() => {
                    setIsModalOpen({open: false, item: undefined});
                    setFiles([])
                    form.resetFields();
                }}
                footer={false}
            >
                <div className="row modal-user">
                    <div className="col-12">
                        <Form layout="vertical" form={form} onFinish={onFinish}>
                            <div className="form-item">
                                <Form.Item
                                    label="First name"
                                    name="firstname"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your username!",
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="form-item">
                                <Form.Item
                                    label="Last name"
                                    name="lastname"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your username!",
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>

                            <div className="form-item">
                                <Form.Item name="images" label="Photos upload">
                                    <Upload
                                        accept="image/*"
                                        fileList={files}
                                        onChange={({fileList: newFileList}) => {
                                            if (newFileList.length > 1) {
                                                form.setFields([{
                                                    name: "images",
                                                    errors: [],
                                                    validating: true
                                                }])
                                            }
                                            setFiles(newFileList.map(item => {
                                                item.status = "done";
                                                return item;
                                            }))
                                        }}
                                        customRequest={({onSuccess}) => {
                                            onSuccess("ok")
                                        }}
                                        listType="picture-card"
                                    >
                                        <div>
                                            <PlusOutlined/>
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </div>
                                    </Upload>
                                </Form.Item>
                            </div>
                            <div className="w-100 d-flex justify-content-end">
                                <Button loading={loaderAction} disabled={loaderAction} htmlType="submit"
                                        type="primary">Submit</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
            {/* Edit modal end */}
        </div>
    );
};

export default Users;
