import {UserOutlined} from "@ant-design/icons";
import React from 'react';
import {Avatar, Button, Card, Checkbox, Col, Form, Input, message, Row} from 'antd';
import instance from '../../config/axios_config';
import {setAuth} from '../../redux/auth/auth_slice';
import {useDispatch} from 'react-redux';
import {ACCESS_TOKEN} from '../../config/constants';

const LoginPage = () => {
    const dispatch = useDispatch()
    const onFinish = async (values) => {
        let data = new FormData();
        Object.entries(values).forEach(([key, val]) => {
            data.append(key, val);
        })
        try {
            let resp = await instance({
                method: "post",
                url: "/login",
                data: data
            })
            if (resp.data.status === 1) {
                message.success(resp.data?.message)
                dispatch(setAuth(true))
                localStorage.setItem(ACCESS_TOKEN, resp.data.data.token)
            }
        } catch (error) {
            message.error(error?.response?.data?.message)
        }
    };
    return (<div>
        <Row className="d-flex justify-content-center align-items-center m-0" style={{height: "80vh"}} gutter={24}>
            <Col span={6}>
                <Card className="shadow">
                    <Row gutter={24}>
                        <Col className="d-flex justify-content-center flex-wrap" span={24}>
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <Avatar className="d-flex align-items-center justify-content-center" style={{backgroundColor: "#f56a00"}} size={100} icon={<UserOutlined/>}/>
                            </div>
                            {/*<h4 className="w-100 text-center">Login</h4>*/}
                        </Col>
                        <Col span={24} className="pt-5">
                            <Form
                                name="basic"
                                layout='vertical'
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Col span={24}>
                                    <Form.Item
                                        label="Login"
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your username!',
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Parol"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password/>
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="d-flex justify-content-center">
                                    <Button type="primary" htmlType="submit">
                                        Kirish
                                    </Button>
                                </Col>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </div>)
}

export default LoginPage