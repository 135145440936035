import {useEffect, useState} from "react";
import {
    CheckCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    GlobalOutlined,
    PlusOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    Button, Col, Form, Input, message, Modal, Popconfirm, Row, Spin, Switch, Tooltip, Upload,
} from "antd";
import "./style.css";
import {
    MapContainer, Marker, Polyline, Popup, TileLayer,
} from "react-leaflet";
import instance from "../../config/axios_config";
import {Map} from "../map";

const Settings = () => {
    const [modal, setModal] = useState({open: false, item: undefined});
    const [center, setCenter] = useState([]);
    const [zoom, setZoom] = useState(10);
    const [joinType, setJoinType] = useState("")
    const [locations, setLocations] = useState([])
    const [wifis, setWifis] = useState([])
    const [item, setItem] = useState();
    const [mode, setMode] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm()


    useEffect(() => {
        (async () => {
            try {
                let resp = await instance({
                    method: 'get', url: '/get-join-type'
                });
                setJoinType(resp?.data?.data);
                if (resp?.data?.data === "WIFI") {
                    let resp = await instance({
                        method: 'get', url: '/get-all-wifi'
                    });
                    setWifis(resp?.data?.data?.rows)
                } else {
                    let resp = await instance({
                        method: 'get', url: '/get-all-location'
                    });
                    setLocations(resp?.data?.data)
                }
            } catch (e) {
                message.error(e?.response?.data?.message);
            }
        })()
    }, [refresh]);

    useEffect(() => {
        // console.log(map.getCenter())
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((e) => {
                setCenter([e.coords.latitude, e.coords.longitude])
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, [])
    const clearData = () => {
        setItem(undefined);
        setMode("");
        setRefresh(!refresh)
    };

    const confirm = (e) => {
        console.log(e);
        message.success("Delete");
    };
    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    const onChange = async (checked) => {
        try {
            let data = new FormData();
            data.append("sid", 1)
            data.append("name", checked ? 'GEOLOCATION' : "WIFI")
            let resp = await instance({
                method: "post", url: '/set-join-type', data: data
            })
            message.success(resp.data.message);
            setRefresh(!refresh);
        } catch (e) {
            message.error(e?.response?.data?.message)
        }
    };

    const onFinish = async (values) => {
        try {
            let data = new FormData();
            data.append("name", values?.name);
            data.append("ip", values?.ip);
            data.append("active", (!(!values?.active)));
            if (modal.item) {
                data.append("id", modal.item.id);
            }
            let resp = await instance({
                method: 'post', url: '/create-or-update-wifi', data: data
            });
            message.success(resp.data.message);
            setRefresh(!refresh);
            setModal({open: false, item: undefined});
            form.resetFields()
        } catch (e) {
            message.error(e?.response?.data?.message)
        }
    }

    const deleteS = async (id) => {
        try {
            let resp = await instance({
                method: 'delete', url: `${joinType === "WIFI" ? '/delete-wifi' : "/delete-location"}?id=${id}`
            });
            message.success(resp.data.message);
            setRefresh(!refresh)
        } catch (e) {
            message.error(e?.response?.data?.message)
        }
    }

    return (<div className="container-fluid">
        <div className="row">
            <div className="col d-flex justify-content-between align-content-center mb-3">
                <h4>Settings</h4>
                <div className="d-flex align-items-center">
                    <Switch
                        className="mx-2"
                        size="large"
                        onChange={onChange}
                        checkedChildren="Location"
                        unCheckedChildren="WiFi"
                        checked={joinType === "GEOLOCATION"}
                    />
                    <Button onClick={() => {
                        if (joinType === "WIFI") {
                            setModal({open: true, item: undefined})
                        } else {
                            setMode("create")
                        }
                    }} type="primary">
                        + Add
                    </Button>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                {joinType === "GEOLOCATION" ? (<div>
                    <table className="table table-striped table-hover hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Geo location</th>
                            <th scope="col">Active</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {// loading ? <div className="w-100 d-flex align-items-center justify-content-center"><Spin /></div>
                            locations.map((item, i) => (<tr key={i}>
                                <th scope="row">{(i + 1)}</th>
                                <td>{item.name}</td>
                                <td>
                                    <Tooltip title="Preview">
                                        <GlobalOutlined
                                            onClick={() => {
                                                setCenter(item.center.coordinates);
                                                setItem({name: item.name, geom: item.geom.coordinates})
                                                setZoom(18);
                                                setMode("view")
                                            }}
                                            style={{
                                                marginRight: "10px", fontSize: "20px", cursor: "pointer",
                                            }}
                                        />
                                    </Tooltip>
                                </td>
                                <td>{item.active ? <CheckCircleOutlined style={{
                                    fontSize: "20px", color: '#52c41a'
                                }}/> : <ExclamationCircleOutlined style={{
                                    fontSize: "20px", color: '#f5222d'
                                }}/>}</td>
                                <td className="action">
                                    <Tooltip title="Edit">
                                        <EditOutlined
                                            onClick={() => {
                                                setItem({
                                                    id: item.id,
                                                    name: item.name,
                                                    geom: item.geom.coordinates,
                                                    active: item.active
                                                });
                                                setMode("update");
                                                setCenter(item?.center?.coordinates);
                                                setZoom(18)
                                            }}
                                            style={{
                                                color: "#52c41a",
                                                marginRight: "10px",
                                                fontSize: "20px",
                                                cursor: "pointer",
                                            }}
                                            className="edit icon"
                                            color="green"
                                        />
                                    </Tooltip>
                                    {/* <Tooltip title="Delete"> */}
                                    <Popconfirm
                                        title="Delete the task"
                                        description="Are you sure to delete this task?"
                                        onConfirm={() => deleteS(item?.id)}
                                        onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteOutlined
                                            style={{
                                                color: "#eb2f96", fontSize: "20px", cursor: "pointer",
                                            }}
                                            className="delete icon"
                                            color="red"
                                        />
                                    </Popconfirm>
                                    {/* </Tooltip> */}
                                </td>
                            </tr>))}
                        </tbody>
                    </table>
                    <Row className="pt-3" gutter={24}>
                        <Col span={24}>
                            {center.length ? <Map clearData={clearData} mode={mode} item={item} center={center}
                                                  zoom={zoom}/> : ''}
                        </Col>
                    </Row>
                </div>) : (<table className="table table-striped table-hover hover">
                    <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Name</th>
                        <th scope="col">IP</th>
                        <th scope="col">Active</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {// loading ? <div className="w-100 d-flex align-items-center justify-content-center"><Spin /></div>
                        wifis?.map((item) => (<tr key={item?.id}>
                            <th scope="row">{item?.id}</th>
                            <td>{item?.name}</td>
                            <td>{item?.ip}</td>
                            <td>{item.active ? <CheckCircleOutlined style={{
                                fontSize: "20px", color: '#52c41a'
                            }}/> : <ExclamationCircleOutlined style={{
                                fontSize: "20px", color: '#f5222d'
                            }}/>}</td>
                            <td className="action">
                                <Tooltip title="Edit">
                                    <EditOutlined
                                        onClick={() => {
                                            setModal({open: true, item: item});
                                            form.setFieldsValue(item)

                                        }}
                                        style={{
                                            color: "#52c41a",
                                            marginRight: "10px",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                        }}
                                        className="edit icon"
                                        color="green"
                                    />
                                </Tooltip>
                                {/* <Tooltip title="Delete"> */}
                                <Popconfirm
                                    title="Delete the task"
                                    description="Are you sure to delete this task?"
                                    onConfirm={() => deleteS(item?.id)}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <DeleteOutlined
                                        style={{
                                            color: "#eb2f96", fontSize: "20px", cursor: "pointer",
                                        }}
                                        className="delete icon"
                                        color="red"
                                    />
                                </Popconfirm>
                            </td>
                        </tr>))}
                    </tbody>
                </table>)}
            </div>
        </div>
        {/* Add modal */}
        <Modal
            title="Add user"
            open={modal.open}
            footer={false}
            onCancel={() => {
                setModal({open: false, item: undefined});
                form.resetFields()
            }}
        >
            <div className="row modal-user">
                <div className="col-12">
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <div className="form-item my-2">
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{
                                    required: true, message: "Please input name!",
                                },]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="form-item my-2">
                            <Form.Item
                                label="Ip address"
                                name="ip"
                                rules={[{
                                    required: true, message: "Please input ip!",
                                },]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>

                        <div className="form-item my-2">
                            <Form.Item name="active" label="active" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                        </div>
                        <div className="form-item d-flex justify-content-end">
                            <Button htmlType="submit" type="primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    </div>);
};

export default Settings;
