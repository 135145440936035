import {Button, Card, Col, Form, Input, message, Row} from "antd";
import {useState} from "react";
import {useDispatch} from "react-redux";
import instance from "../../config/axios_config";
import {ACCESS_TOKEN} from "../../config/constants";
import {setAuth} from "../../redux/auth/auth_slice";

const UpdatePassword = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false);
    const onFinish = async (vals) => {
        if (vals["new_password"] && vals["new_password"].length >= 8 && vals["new_password"] === vals["re_new_password"]) {
            setLoad(true)
            form.setFields([{
                name: "new_password",
                errors: "",
                validating: true
            }, {
                name: "re_new_password",
                errors: "",
                validating: true
            }])
            try {
                let data = new FormData();
                data.append("new_password", vals["new_password"])
                let resp = await instance({
                    url: "/user/update-admin-password",
                    method: "POST",
                    data: data
                })
                message.success(resp.data.message);
                form.resetFields();
                setLoad(false);
                localStorage.removeItem(ACCESS_TOKEN);
                dispatch(setAuth(false))
            } catch (e) {
                message.error("Error update password!")
                setLoad(false)
            }
        } else if (vals["new_password"].length < 8) {
            form.setFields([{
                name: "new_password",
                errors: ["Parol uzunligi 8 dan kam bo'lishi mumkin emas!"],
                validating: false
            }])
        } else {
            form.setFields([{
                name: ["re_new_password"],
                errors: ["Parollar mutanosib emas!"],
                validating: false
            }, {
                name: ["new_password"],
                errors: ["Parollar mutanosib emas!"],
                validating: false
            }])
        }
    }

    return (<div>
        <Form onFinish={onFinish} form={form} layout="vertical">
            <Row gutter={24} className="d-flex justify-content-center">
                <Col span={8}>
                    <Card className="shadow">
                        <Row gutter={24}>
                            <Col span={24} className="d-flex justify-content-center">
                                <h4>Parolni yangilash!</h4>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="new_password" label="Parol">
                                    <Input.Password allowClear={true} onBlur={() => {
                                        let vals = form.getFieldsValue();
                                        if (vals["re_new_password"] && vals["re_new_password"] !== vals["new_password"]) {
                                            console.log(vals)
                                            form.setFields([{
                                                name: ["re_new_password"],
                                                errors: ["Parollar mutanosib emas!"],
                                                validating: false
                                            }, {
                                                name: ["new_password"],
                                                errors: ["Parollar mutanosib emas!"],
                                                validating: false
                                            }])
                                        } else {
                                            form.setFields([{
                                                name: ["re_new_password"],
                                                errors: [""],
                                                validating: true
                                            }, {
                                                name: ["new_password"],
                                                errors: [""],
                                                validating: true
                                            }])
                                        }
                                    }} placeholder="Yangi parolni yozing!"/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="re_new_password" label="Parolni qayta tering">
                                    <Input.Password allowClear={true} onBlur={() => {
                                        let vals = form.getFieldsValue();
                                        if (!vals["new_password"]) {
                                            form.setFields([{
                                                name: ["new_password"],
                                                errors: ["Iltimos parolni kiriting!!"],
                                                validating: false
                                            }])
                                        } else if (vals["re_new_password"] !== vals["new_password"]) {
                                            console.log(vals)
                                            form.setFields([{
                                                name: ["re_new_password"],
                                                errors: ["Parollar mutanosib emas!"],
                                                validating: false
                                            }, {
                                                name: ["new_password"],
                                                errors: ["Parollar mutanosib emas!"],
                                                validating: false
                                            }])
                                        } else {
                                            form.setFields([{
                                                name: ["re_new_password"],
                                                errors: [""],
                                                validating: true
                                            }, {
                                                name: ["new_password"],
                                                errors: [""],
                                                validating: true
                                            }])
                                        }
                                    }} placeholder="Parolni qayta tering!"/>
                                </Form.Item>
                            </Col>
                            <Col span={24} className="d-flex justify-content-end">
                                <Button loading={load} htmlType="submit" type="primary">Saqlash</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </div>)
}

export default UpdatePassword